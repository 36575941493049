import React, { useState } from 'react'
import Income from './income'
import Overview from './overview'
import OpportunitiesChart from './opportunitiesChart'
import { Row, Col, Select } from 'antd'
import Promotions from './promotions'

const ResellerDashboard = ({ uid, t, role, opportunities, payments, commissions, filters, intervals, view, users, products, income, isMobile }) => (
  <Row>
    <Col xs={24} lg={16}>
      <Row>
        <Col xs={24} className="padding">
          <Income
            t={t}
            role="reseller"
            income={income}
            intervals={intervals}
            paginated={isMobile && isMobile}
          />
        </Col>
        <Col xs={24} lg={12} className="padding">
          <Overview
            t={t}
            role="reseller"
            payments={payments}
            commissions={commissions}
            opportunities={opportunities}
            filters={filters}
          />
        </Col>
        <Col xs={24} md={24} lg={12} className="padding">
          <OpportunitiesChart
            t={t}
            role="agent"
            opportunities={opportunities}
            intervals={intervals}
          />
        </Col>
      </Row>
    </Col>
    <Col xs={24} lg={8} className="padding">
      <Promotions
        t={t}
        role="reseller"
        uid={uid}
        products={products
          .filter(prod => (prod.agent === uid || prod.agent === 'perpetual') && prod.promotion_rate)
          .map(prod => ({ 
            label: prod.name, 
            value: prod.id,
            promotion_rate: prod.promotion_rate, 
            retail_fullpayment: prod.retail_fullpayment,
            id: prod.id
          }))
        }
      />
      </Col>
  </Row>
)

export default ResellerDashboard;