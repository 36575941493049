import React from 'react'
import numeral from 'numeral';
import { Row, Col } from 'antd'

const formatNumber = (num) => numeral(num).format('0,0.00')


const AgentsOverview = ({ t, users, resellers, filters, individual }) => {

  const from = filters.dates[0].valueOf()
  const to = filters.dates[1].valueOf()

  const getLinksAmount = type => {
    if (!users) return '-'
    return type === 'shared'
      ? users.reduce((acc, user) => user.sharedLink + acc, 0)
      : users.reduce((acc, user) => user.openedLink + acc, 0)
  }

  const getResellerConversion = () => {

    let active_amount = resellers.filter(res => 
      res.status === 'Active' && 
      (!res.registeredAt || (res.registeredAt >= from && res.registeredAt <= to))
    ).length

    let total_amount = resellers.filter(res => res.createdAt >= from && res.createdAt <= to).length
    
    let ratio = total_amount === 0 
      ? '-' 
      : (active_amount / total_amount) * 100
    return ratio === '-'
      ? '-'
      : `${formatNumber(ratio)} %`
  }

  return (
    <Row className="chart-container agents-overview">
      <Col xs={24}><p>{individual ? t('Individual performance') : t('Overview')}</p></Col>
      <Number text={t('Overall number of Shared Links')} amount={getLinksAmount('shared')} className="secondary bigger" size={12} />
      <Number text={t('Overall number of Opened Shared Links')} amount={getLinksAmount('opened')} className="primary bigger" size={12} />
      <Number text={t('Overall Customer to Reseller conversion rate')} amount={getResellerConversion()} className="gray bigger" size={24} />
    </Row>
  )
}

const Number = ({ text, amount, className, size }) => (
  <Col xs={size}>
    <div className={`number ${className}`}>
      <h4>{text}</h4>
      <p>{amount}</p>
    </div>
  </Col>
)

export default AgentsOverview;