import React, { useState } from 'react'
import { Row, Col } from 'antd'
import moment from 'moment'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import NoData from '../../../assets/illustrations/nodata.svg'

const OpportunitiesChart = ({ t, role, opportunities, intervals }) => {

  const [ startAt, setStartAt ] = useState(0)

  const getData = () => {
    let data = []
    intervals.map( int => {
      let open_amount = opportunities.filter(opp => opp.status === 'Open' && opp.updatedAt > int.from && opp.updatedAt < int.to).length
      let lost_amount = opportunities.filter(opp => opp.status === 'Lost' && opp.updatedAt > int.from && opp.updatedAt < int.to).length
      let closed_amount = opportunities.filter(opp => opp.status === 'Closed' && opp.updatedAt > int.from && opp.updatedAt < int.to).length
      data.push({
        date: int.text,
        Open: open_amount,
        Lost: lost_amount,
        Closed: closed_amount
      })
    })
    return data
  }

  const data = getData().slice(startAt, startAt + 3)

  const getLabel = (label) => {
    let currentInterval = intervals.find(int => int.text === label)
    if (currentInterval) {
      return `${moment(currentInterval.from).format('DD. MMM')} - ${moment(currentInterval.to).format('DD. MMM')}`
    } else {
      return label
    }
  }


  const hasData = getData().filter( item => item.Open || item.Lost || item.Closed ).length !== 0


  return (
    <div className="chart-container">
      <p>{t('Number of Opportunities')}</p>
      {hasData ?
        <ResponsiveContainer width="80%" height={280}>
          <LineChart
            width="100%"
            height={280}
            data={data}
          >
            <XAxis dataKey="date" />
            <YAxis tickCount={3} />
            <Tooltip 
              labelFormatter={label => label.length === 3 || label.length === 4
                ? label //Month name
                :  getLabel(label)
              }
            />
            <Legend align="left" verticalAlign="middle" iconType="circle" />
            <Line type="monotone" dataKey="Open" stroke="#FFF4F4" activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="Lost" stroke="#BA4A00" />
            <Line type="monotone" dataKey="Closed" stroke="#398CAF" />
          </LineChart>
        </ResponsiveContainer>
      : <div className="no-data">
          <img src={NoData} />
          <p>{t('No data yet')}</p>
        </div>
      }
      {hasData &&
        <div className="controls">
          <LeftOutlined onClick={() => startAt !== 0 && setStartAt(val => val - 3)}  />
          <RightOutlined onClick={() => startAt < data.length && setStartAt(val => val + 3)} />
        </div>
      }
    </div>
  )
}

export default OpportunitiesChart;