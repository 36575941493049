import React, { useEffect, useState } from 'react'
import './header.module.scss'
import { Button, Select, Popover, Checkbox, DatePicker, Radio } from 'antd'
import { DownOutlined, CloseOutlined } from '@ant-design/icons'
import moment from 'moment'
import { Mobile, Desktop } from '../../utils/responsive'
const { Option } = Select
const { RangePicker } = DatePicker

const Header = ({ view, role, t, setView, filters, adjustFilters, products, uid}) => {

  const [ dateRange, setDateRange ] = useState(filters.dates)
  const [selectedYear, setSelectedYear] = useState(moment().format('YYYY'))
  const [ rangePickerOpen, setRangePickerOpen ] = useState(false)

  const getAvailableYears = () => {
    const currentYear = moment().format('YYYY')
    let years = []
    for (let year = 2021; year <= currentYear; year++) {
      years.push(year)
    }
    return years
  }

  console.log(getAvailableYears())

  const setMonthlyPeriod = month => {
    month = month < 10 ? `0${month}` : month
    const from = moment(`${selectedYear}-${month}-01`, 'YYYY-MM-DD')
    const to = moment(`${selectedYear}-${month}-01`, 'YYYY-MM-DD').endOf('month')
    setDateRange([from, to])
  }

  const setYearlyPeriod = year => {
    setSelectedYear(year)
    const from = moment(selectedYear, 'YYYY').startOf('year')
    const to = moment(selectedYear, 'YYYY').endOf('year')
    setDateRange([from, to])
  }

  const getRangePicker = (bordered) => (
    <RangePicker
      value={dateRange}
      separator="-"
      open={rangePickerOpen}
      onChange={(dates) => setDateRange(dates)}
      allowClear={false}
      bordered={bordered}
      onOpenChange={open => !open && setDateRange(filters)}
      renderExtraFooter={() => (
        <div className="rangepicker-footer">
          <p>{t('You can view your dashboard on a monthly basis or select a certain period of time.')}</p>
          <div>
            <Select defaultValue={t('Show Years')} onChange={year => setYearlyPeriod(year)}>
              {getAvailableYears().map((year) => <Option value={year}>{year}</Option>)}
            </Select>
            <Select defaultValue={t('Show Months')} onChange={month => setMonthlyPeriod(month)}>
              {months.map((month, index) => <Option value={index + 1}>{t(month)}</Option>)}
            </Select>
          </div>
          <div className="controls">
            <Button
              onClick={(e) => {
                e.stopPropagation()
                setRangePickerOpen(false)
              }}
              className="secondary-medium-2"
            >
              {t('Cancel')}
            </Button>
            <Button
              className="primary-medium-2"
              onClick={(e) => {
                e.stopPropagation()
                setRangePickerOpen(false)
                adjustFilters('dates', dateRange)
              }}
            >
              {t('View')}
            </Button>
          </div>
        </div>
      )}
    />
  )


  return (
    <div className="dashboard-header">
      <Desktop>
        <div className="left-section">
          <Button data-title="My sales" onClick={() => setView('sales')} className={view === 'sales' ? 'view-btn active' : 'view-btn'}>
            {role === 'admin' || role === 'superadmin' ? t('Sales') : t('My sales')}
          </Button>
          {role !== 'reseller' &&
            <Button data-title="Agent & Resellers Performance" onClick={() => setView('agents')} className={view === 'agents' ? 'view-btn active' : 'view-btn'}>
              {role === 'admin' || role === 'superadmin' ? t('Agent & Resellers Performance') : t("My Reseller's Performance")}
            </Button>
          }
        </div>
      </Desktop>
      <Mobile>
        <div className="mobile-header">
          <div className="selectors">
            <Radio.Group value={view} onChange={e => setView(e.target.value)} buttonStyle="solid" className={`section-selector ${role === 'reseller' && 'reseller'}`}>
              <Radio.Button value="sales">{role === 'admin' || role === 'superadmin' ? t('Sales') : role == 'reseller' ? t('My sales') : t('My sales')}</Radio.Button>
              {role !== 'reseller' && <Radio.Button value="agents">{role === 'agent' ? t('My resellers') : t('Agents/Resellers')}</Radio.Button>}
            </Radio.Group>
            <div onClick={() => setRangePickerOpen(true)}>
                {getRangePicker(false)}
            </div>
          </div>
          <div className="date-display">
            <div>
              {view === 'sales'
                ? <p>{t('An overview of current and future revenues')}</p>
                : <p>{t('An overview on the performance of agents and resellers')}</p>
              }
            </div>
            <div className="bold">
              {moment(dateRange[0]).format('DD MMM YYYY')} - {moment(dateRange[1]).format('DD MMM YYYY')}
            </div>
          </div>
        </div>
      </Mobile>
        <div className="right-section">
          <Desktop>
            <div className="inline" onClick={() => setRangePickerOpen(true)}>
              {view === 'sales'
                ? <p>{t('An overview of current and future revenues')}</p>
                : <p>{t('An overview on the performance of agents and resellers')}</p>
              }
              {getRangePicker(true)}
            </div>
          </Desktop>
        {view === 'sales' && role !== 'reseller' &&
          <div className="filters">
            <Selector
              options={products.map( prod => ({label: prod.name, value: prod.id}))}
              values={filters.products}
              onChange={values => adjustFilters('products', values)}
              text={t('All products')}
            />
            <Selector
              options={products.filter(prod => prod.agent === uid).map(prod => ({ label: prod.name, value: prod.id }))}
              values={filters.agent_products}
              onChange={values => adjustFilters('agent_products', values)}
              text={role === 'admin' || role === 'superadmin' ? t('Agent products') : t('My Products')}
            />
            {role === 'admin' || role === 'superadmin' &&
              <Selector
                options={products.filter(prod => prod.agent === 'perpetual').map(prod => ({ label: prod.name, value: prod.id }))}
                values={filters.perpetual_products}
                onChange={values => adjustFilters('perpetual_products', values)}
                text={t('Perpetual products')}
              />
            }
          </div>
        }
      </div>
    </div>
  )
}


const Selector = ({options, values, onChange, text}) => (
  <Popover
    overlayClassName={values.length === 0 ? 'selector' : 'selector active'}
    content={
      <div className="checkbox-group">
        {options.map( opt => 
          <Checkbox 
            value={opt.value} 
            checked={values.includes(opt.value) ? true : false}
            onChange={e => {
              if (e.target.checked) {
                onChange([...values, opt.value])
              } else {
                onChange(values.filter( val => val !== opt.value))
              }
            }}
          >
              {opt.label}
          </Checkbox>
        )}
      </div>
    }
  >
    <Button className={values.length > 0 ? 'active' : ''}>
      {text}
      {values.length === 0
        ? <DownOutlined />
        : <CloseOutlined
            onClick={(e) => {
              e.stopPropagation()
              onChange([])
            }}
          />
      }
    </Button>
  </Popover>
)

export default Header;

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];