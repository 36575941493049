import React from 'react'
import numeral from 'numeral';
import { Row, Col } from 'antd'
import NoData from '../../../assets/illustrations/nodata.svg'

const NumberOfOpportunities = ({ t, role, opportunities }) => {

  const getData = () => {
    let small_amount = 0;
    let medium_amount = 0;
    let big_amount = 0;

    opportunities.map( opp => {
      if (opp.value > 1000000) {
        big_amount += 1
      } else if (opp.value >= 500000 && opp.value <= 1000000) {
        medium_amount += 1
      } else {
        small_amount += 1
      }
    })

    let max = Math.max(small_amount, medium_amount, big_amount)

    return { 
      small: {
        amount: small_amount,
        width: (small_amount / max) * 100 / 2
      }, 
      medium: {
        amount: medium_amount,
        width: (medium_amount / max) * 100 / 2
      }, 
      big: {
        amount: big_amount,
        width: (big_amount / max) * 100 / 2
      }
    }
  }

  const { small, medium, big } = getData()
  const hasData = small || medium || big

  return (
    <div className={`chart-container opportunities-amount ${!hasData && 'nodata'}`}>
      <p>{t('Number of Opportunities and their Value')}</p>
      {hasData ?
        <div>
          <p className="sub-title">{t('Opportunities of RM 0 - 500k')}</p>
          <div className="bar" style={{width: `${small.width}%`}}>{small.amount}</div>
          <p className="sub-title">{t('Opportunities of RM 500k - 1m')}</p>
          <div className="bar" style={{ width: `${medium.width}%` }}>{medium.amount}</div>
          <p className="sub-title">{t('Opportunities of RM 1m - 2m')}</p>
          <div className="bar" style={{ width: `${big.width}%` }}>{big.amount}</div>
        </div>
      : <div className="no-data">
          <img src={NoData} />
          <p>{t('No data yet')}</p>
        </div>
      }
    </div>
  )
}

export default NumberOfOpportunities;