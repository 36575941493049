import React from 'react'
import Income from './income'
import Overview from './overview'
import OpportunitiesChart from './opportunitiesChart'
import Promotions from './promotions'
import { Row, Col, Select } from 'antd'
import Revenue from './revenue'

const AgentDashboard = ({ uid, t, role, opportunities, payments, commissions, filters, intervals, view, users, resellers, income, isMobile, products }) => {

  if (view === 'sales') {
    return (
      <Row className="agent-dashboard-sales">
        <Col xs={24} lg={16}>
          <Row>
            <Col xs={24} className="padding">
              <Income
                t={t}
                role="agent"
                income={income}
                intervals={intervals}
                paginated={isMobile && isMobile}
              />
            </Col>
          <Col xs={24} md={12} className="padding">
            <OpportunitiesChart
              t={t}
              role="agent"
              opportunities={opportunities}
              intervals={intervals}
            />
          </Col>
          <Col xs={24} md={12} className="padding">
            <Overview
              t={t}
              role="agent"
              payments={payments}
              commissions={commissions}
              opportunities={opportunities}
              filters={filters}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} lg={8} className="padding">
        <Promotions
          t={t}
          uid={uid}
          role='agent'
          products={products
            .filter(prod => (prod.agent === uid || prod.agent === 'perpetual') && prod.promotion_rate)
            .map(prod => ({ 
              label: prod.name, 
              value: prod.id,
              promotion_rate: prod.promotion_rate, 
              retail_fullpayment: prod.retail_fullpayment,
              id: prod.id
            }))
          }
        />
      </Col>
    </Row>
    )
  } else {
    return (
      <Row>
        <Col xs={24} lg={16} className="padding">
          <Revenue
            t={t}
            intervals={intervals}
            role="agent"
          />
        </Col>
        <Col xs={24} lg={8} className="padding">
          <Overview
            t={t}
            role="agent"
            payments={payments.filter(payment => payment.commissionaireId !== uid)}
            commissions={commissions.filter(com => com.commissionaireId !== uid)}
            opportunities={opportunities.filter( opp => opp.reseller)}
            filters={filters}
          />
        </Col>
        <Col xs={24} md={12} lg={8} className="padding">
          <OpportunitiesChart
            t={t}
            role="agent"
            opportunities={opportunities.filter(opp => opp.reseller)}
            intervals={intervals}
          />
        </Col>
      </Row>
    )
  }
}

export default AgentDashboard;