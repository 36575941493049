import React, { useState} from 'react'
import numeral from 'numeral';
import { Table } from 'antd'
import sortTable from '../../utils/sorter'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Mobile, Desktop } from '../../utils/responsive'

const formatNumber = (num) => numeral(num).format('0,0.00')

const Leaderboard = ({ t, payments, commissions, filters, opportunities, users, isMobile }) => {

  const [sorter, setSorter] = useState({
    columnKey: 'opportunities_amount',
    field: 'opportunities_amount',
    order: 'descend'
  })
  const [ currentPage, setCurrentPage ] = useState(1)

  const getData = () => {
    let from = filters.dates[0].valueOf()
    let to = filters.dates[1].valueOf()
    let data = []
    users.map( user => {
      let { uid, role, name } = user
      let user_opportunities = opportunities.filter(opp => opp.status === 'Closed' && ((role === 'agent' && opp.agentId === uid && !opp.reseller) || (role === 'reseller' && opp.reseller === uid)))
      let opportunities_amount = user_opportunities.length
      let commission = commissions.find( comm => comm.commissionaireId === uid)
      let user_payments = payments.filter(payment => payment.commissionaireId === uid)
      let revenue = 0
      if (opportunities_amount !== 0) {
        user_payments.map( payment => {
          payment.events.map( event => {
            if (event.status === 'Cleared' && event.createdAt >= from && event.createdAt <= to) {
              revenue += event.amount
            } 
          })
        })
      }

      data.push({
        name,
        role,
        opportunities_amount,
        revenue,
        commission: commission 
          ? commission.items.reduce((acc, item) => {
              if (item.dueAt >= from && item.dueAt <= to) {
                return item.amount + acc
              } else {
                return acc
              }
            }, 0)
          : 0
      })
    })
    let arrayFrom = (currentPage - 1) * 10
    let arrayTo = currentPage * 10
    return data.slice(arrayFrom, arrayTo)
  }

  const getColumns = () => {
    if (isMobile) {
      return [
        {
          title: t('Ranking'),
          dataIndex: 'ranking',
          key: 'ranking',
          render: (text, record, index) => (currentPage - 1) * 10 + index + 1,
          width: 50
        },
        {
          title: t('Name'),
          dataIndex: 'name',
          key: 'name',
          sorter: (a, b) => sortTable(a, b, 'name'),
          sortOrder: sorter.columnKey === 'name' && sorter.order
        },
        {
          title: t('Revenue'),
          dataIndex: 'revenue',
          key: 'revenue',
          sorter: (a, b) => sortTable(a, b, 'revenue'),
          sortOrder: sorter.columnKey === 'revenue' && sorter.order,
          render: text => `RM ${formatNumber(text)}`
        },
      ]
    } else {
      return [
        {
          title: t('Ranking'),
          dataIndex: 'ranking',
          key: 'ranking',
          render: (text, record, index) => (currentPage - 1) * 10 + index + 1,
        },
        {
          title: t('Name'),
          dataIndex: 'name',
          key: 'name',
          sorter: (a, b) => sortTable(a, b, 'name'),
          sortOrder: sorter.columnKey === 'name' && sorter.order
        },
        {
          title: t('Role'),
          dataIndex: 'role',
          key: 'role',
          sorter: (a, b) => sortTable(a, b, 'role'),
          sortOrder: sorter.columnKey === 'role' && sorter.order
        },
        {
          title: t('Closed Opportunities'),
          dataIndex: 'opportunities_amount',
          key: 'opportunities_amount',
          sorter: (a, b) => sortTable(a, b, 'opportunities_amount'),
          sortOrder: sorter.columnKey === 'opportunities_amount' && sorter.order
        },
        {
          title: t('Revenue'),
          dataIndex: 'revenue',
          key: 'revenue',
          sorter: (a, b) => sortTable(a, b, 'revenue'),
          sortOrder: sorter.columnKey === 'revenue' && sorter.order,
          render: text => `RM ${formatNumber(text)}`
        },
        {
          title: t('Commission'),
          dataIndex: 'commission',
          key: 'commission',
          sorter: (a, b) => sortTable(a, b, 'commission'),
          sortOrder: sorter.columnKey === 'commission' && sorter.order,
          render: text => `RM ${formatNumber(text)}`
        },
      ]
    }
  }

  const tableData = getData()
  const maxPage = Math.floor(tableData.length / 10)

  return (
    <div>
      <div className="chart-container leaderboard">
        <p>{t('Leaderboard')}</p>
        <div className="inline">
          <div className="table">
            <Table
              dataSource={tableData}
              columns={getColumns()}
              onChange={(pagination, filers, sorter) => setSorter(sorter)}
              pagination={false}
              size="small"
            />
          </div>
          <Desktop>
            <div className="numbers">
              <div className="number secondary">
                <h4>{t('Total number of Agents')}</h4>
                <p>{users.filter(user => user.role === 'agent').length}</p>
              </div>
              <div className="number primary">
                <h4>{t('Total number of Resellers')}</h4>
                <p>{users.filter(user => user.role === 'reseller').length}</p>
              </div>
            </div>
          </Desktop>
        </div>
        <div className="controls">
          <LeftOutlined onClick={() => currentPage !== 1 && setCurrentPage(val => val-1)} />
          <RightOutlined onClick={() => currentPage <= maxPage && setCurrentPage(val => val+1)} />
        </div>
      </div>
      <Mobile>
        <div className="chart-container">
          <div className="numbers">
            <div className="number secondary">
              <h4>{t('Total number of Agents')}</h4>
              <p>{users.filter(user => user.role === 'agent').length}</p>
            </div>
            <div className="number primary">
              <h4>{t('Total number of Resellers')}</h4>
              <p>{users.filter(user => user.role === 'reseller').length}</p>
            </div>
          </div>
        </div>
      </Mobile>
    </div>
  )
}

export default Leaderboard;