import React, { useEffect, useState } from 'react'
import { gql } from "@apollo/client";
import Income from './income'
import withApollo, { apolloClient } from '../../utils/graphql';

const Revenue = ({ t, selectedUser, intervals, role }) => {

  const [ income, setIncome ] = useState([])

  useEffect(() => {
    if (selectedUser) {
      getIncome()
    }
  }, [selectedUser])

  useEffect(() => {
    if (role === 'agent') {
      getIncome(true)
    }
  }, [])

  const getIncome = async (onlyResellers) => {
    const { data: income } = await apolloClient.query({
      query: GET_INCOME,
      fetchPolicy: 'network-only',
      variables: {
        intervals,
        onlyResellers,
        uid: selectedUser
      }
    })
    setIncome(income ? income.getIncome : [])
  }

  return (
    <Income
      t={t}
      role={role}
      income={income}
      intervals={intervals}
      paginated={true}
    />
  )
}

export default Revenue;

const GET_INCOME = gql`
  query getIncome($intervals: [interval], $onlyResellers: Boolean, $uid: ID) {
    getIncome(intervals: $intervals, onlyResellers: $onlyResellers, uid: $uid) {
      from
      to
      text
      Revenue
      Commission
      forecasted
    }
  }
`