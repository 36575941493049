import React, { useEffect, useState } from 'react'
import { withTranslation } from '../../i18next'
import '../components/dashboard/index.module.scss'
import Header from '../components/dashboard/header'
import AdminDashboard from '../components/dashboard/adminDashboard'
import AgentDashboard from '../components/dashboard/agentDashboard'
import ResellerDashboard from '../components/dashboard/resellerDashboard'
import compose from '../utils/compose'
import { gql } from "@apollo/client";
import withApollo, { apolloClient } from '../utils/graphql';
import moment from 'moment'
import Head from 'next/head'
import { useRouter } from 'next/router'


const Dashboard = ({ auth, t, isMobile }) => {

  const router = useRouter()
  const { role, uid, mode } = auth.user

  const [ view, setView ] = useState('sales')

  const [ filters, setFilters ] = useState({
    dates: [moment().subtract(2, 'months'), moment().add(5, 'months')],
    products: [],
    agent_products: [],
    perpetual_products: []
  })

  const [products, setProducts ] = useState([])

  const [ opportunities, setOpportunities ] = useState([])
  const [ payments, setPayments ] = useState([])
  const [ commissions, setCommissions ] = useState([])
  const [ users, setUsers ] = useState([])
  const [resellers, setResellers ] = useState([])
  const [ income, setIncome ] = useState([])

  useEffect(() => {
    if (role) {
      getProducts()
      getData()
    }
    if (role === 'reseller' && (!mode || mode === 'basic') && !router.query.intro && !router.query.switch) {
      router.push('/basic')
    }
  }, [role])

  useEffect(() => {
    getData()
  }, [filters])

  const getChartInterval = () => {

    const copy = date => date ? moment(date.valueOf()) : date

    let from_date = copy(filters.dates[0]) //Make a copy of the moment object, instead of modifing the original
    let to_date = copy(filters.dates[1])

    let difference_in_days = to_date.diff(from_date, 'days')


    let intervals = []
    let steps_days, timeFormat, interval_period;
    if (difference_in_days < 14) {
      steps_days = 1
      interval_period = 'day'
      timeFormat = 'DD-MM'

    } else if (difference_in_days < 60) {
      steps_days = 7
      interval_period = 'week'
      timeFormat = 'DD-MM'
    } else if (difference_in_days === 364 || difference_in_days === 365) {
      steps_days = 30
      interval_period = 'month'
      timeFormat = 'MMM'
    } else {
      steps_days = 30
      interval_period = 'month'
      timeFormat = 'DD-MM'
    }
    let interval_length = difference_in_days/steps_days
    for (let i = 0; i <= interval_length; i++) {
      let interval_item = {
        from: copy(from_date).add(i, interval_period).valueOf(),
        to: copy(from_date).add(i + 1, interval_period).valueOf(),
        text: copy(from_date).add(i, interval_period).format(timeFormat)
      }
      intervals.push(interval_item)
    }
    return intervals
  }

  const getProducts = async () => {
    const { data } = await apolloClient.query({
      query: GET_PRODUCTS,
      variables: {
        mode: 'All'
      }
    })
    if (data.getProducts) {
      setProducts(data.getProducts)
    }
  }

  const intervals = getChartInterval()

  const getData = async () => {
    console.log('gettring data')
    //Opportunities
    const { data: opportunities } = await apolloClient.query({
      query: GET_LEADS,
      variables: {
        from_date: filters.dates[0].valueOf(),
        to_date: filters.dates[1].valueOf(),
        products: filters.products,
        agent: role === 'agent'
          ? [uid]
          : null,
        reseller: role === 'reseller'
          ? [uid]
          : null
      }
    })
    console.log('opportunities', opportunities)
    setOpportunities(opportunities ? opportunities.getLeads : [])
  
    let opportunityIds = opportunities
      ? opportunities.getLeads.map( opp => opp.id)
      : []

    //Payments
    const { data: payments } = await apolloClient.query({
      query: GET_PAYMENTS,
      variables: {
        opportunityIds
      }
    })
    console.log('payments', payments)
    setPayments(payments ? payments.getPayments : [])
    //Commissions
    const { data: commissions } = await apolloClient.query({
      query: GET_COMMISSIONS,
      variables: {
        from_date: filters.dates[0].valueOf(),
        to_date: filters.dates[1].valueOf()
      }
    })
    setCommissions(commissions ? commissions.getCommissions : [])
    //Reseller amount
    const { data: resellers } = await apolloClient.query({
      query: GET_RESELLERS,
      variables: {
        agent: role === 'agent'
          ? [uid]
          : null
      }
    })
    setResellers(resellers.getResellers)
    //Income + future income
    const { data: income } = await apolloClient.query({
      query: GET_INCOME,
      variables: {
        intervals,
        uid: role === 'agent' || role === 'reseller'
          ? uid
          : null
      }
    })
    setIncome(income ? income.getIncome : [])
    //Users
    const { data: users } = await apolloClient.query({
      query: GET_USERS,
      variables: {
        links_from: filters.dates[0].valueOf(),
        links_to: filters.dates[1].valueOf(),
      }
    })
    setUsers(users ? users.getAllUsers : [])
  }



  const adjustFilters = (key, value) => {
    setFilters({
      ...filters,
      [key]: value
    })
  }

  const renderComponent = () => {
    if (!role) {
      return <div />
    } else if (role === 'admin' || role === 'superadmin') {
      return (
        <AdminDashboard
          t={t}
          role="admin"
          payments={payments}
          commissions={commissions}
          opportunities={opportunities}
          filters={filters}
          intervals={intervals}
          view={view}
          users={users}
          resellers={resellers}
          income={income}
          isMobile={isMobile}
        />
      )
    } else if (role === 'agent') {
      return (
        <AgentDashboard
          t={t}
          role="admin"
          payments={payments}
          commissions={commissions}
          opportunities={opportunities}
          filters={filters}
          intervals={intervals}
          view={view}
          users={users}
          resellers={resellers}
          income={income}
          isMobile={isMobile}
          uid={uid}
          products={products}
        />
      )
    } else {
      return (
      <ResellerDashboard
        t={t}
        role="admin"
        payments={payments}
        commissions={commissions}
        opportunities={opportunities}
        filters={filters}
        intervals={intervals}
        view={view}
        users={users}
        resellers={resellers}
        income={income}
        isMobile={isMobile}
        uid={uid}
        products={products}
      />
      )
    }
  }


  return (
    <section id="dashboard">
      <Head><title>Dashboard</title></Head>
      <Header
        t={t}
        view={view}
        setView={setView}
        role={auth.user.role}
        filters={filters}
        adjustFilters={adjustFilters}
        products={products}
        uid={uid}
      />
      {renderComponent()}
    </section>
  )
}

Dashboard.getInitialProps = async () => {
  return { namespacesRequired: ['all'] }
}

export default compose(
  withApollo({ ssr: true }),
  withTranslation(['all'])
)(Dashboard)

const GET_PRODUCTS = gql`
  query GetProduct($mode: mode) {
    getProducts(mode: $mode) {
      agent
      name
      id
      promotion_rate
      retail_fullpayment
    }
  }
`

const GET_LEADS = gql`
  query GetLeads($from_date: Float, $to_date: Float, $products: [ID], $agent: [String]) {
    getLeads(from_date: $from_date, to_date: $to_date, products: $products, agent: $agent) {
      updatedAt
      agent
      reseller
      status
      id
      value
      agentId
    }
  }
`

const GET_PAYMENTS = gql`
  query getPayments($opportunityIds: [ID]) {
    getPayments(opportunityIds: $opportunityIds) {
      instalmentPeriod
      instalmentAmount
      opportunityId
      value
      updatedAt
      commissionaireId
      agentId
      commission_percent
      paidAmount
      events {
        amount
        createdAt
        paidAt
        status
      }
    }
  }
`

const GET_COMMISSIONS = gql`
  query getCommissions($from_date: Float, $to_date: Float) {
    getCommissions(from_date: $from_date, to_date: $to_date) {
      commissionaireId
      items {
        amount
        dueAt
        opportunityId
      }
    }
  }
`

const GET_RESELLERS = gql`
  query GetResellers($agent: [String]) {
    getResellers(agent: $agent) {
      agent
      status
      createdAt
      registeredAt
    }
  }
`

const GET_USERS = gql`
  query GetAllUsers($links_from: Float $links_to: Float) {
    getAllUsers(role: ["agent", "reseller"], links_from: $links_from, links_to: $links_to) {
      name
      role
      invitedBy
      status
      uid
      sharedLink
      openedLink
    }
  }
`

const GET_INCOME = gql`
  query getIncome($intervals: [interval], $uid: ID) {
    getIncome(intervals: $intervals, uid: $uid) {
      from
      to
      text
      Revenue
      Commission
      forecasted
    }
  }
`