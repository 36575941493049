import React, { useState } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import NoData from '../../../assets/illustrations/nodata.svg'

const Income = ({t, role, income, intervals, paginated, tallerChart}) => {

  const [startAt, setStartAt] = useState(0)

  const format = num => Number(num.toFixed(2))

  const getLabel = (label) => {
    let currentInterval = intervals.find(int => int.text === label)
    if (currentInterval) {
      return `${moment(currentInterval.from).format('DD. MMM')} - ${moment(currentInterval.to).format('DD. MMM')}`
    } else {
      return label
    }
  }

  income = income.map( (int, i) => {
    if (int.forecasted) {
      let item = {
        text: int.text,
        ['Revenue ']: format(int.Revenue),
        ['Commission ']: format(int.Commission)
      }
      if (income[i - 1] && !income[i-1].forecasted) {
        item.Revenue = format(int.Revenue)
        item.Commission = format(int.Commission)
      }
      return item
    } else {
      return {
        text: int.text,
        Revenue: format(int.Revenue),
        Commission: format(int.Commission)
      }
    }
  })

  if (paginated) {
    income = income.slice(startAt, startAt + 3)
  }


  const hasData = income.filter( item => item.Revenue || item.Commission || item['Revenue '] || item['Commission '] ).length !== 0


  return (
    <div className={`chart-container income-chart ${role === 'admin' ? 'admin' : 'agent-reseller'}`}>
      <p>{t('Income')}</p>
      {hasData ?
        <ResponsiveContainer width="80%" height={tallerChart ? 400 : 280}>
          <LineChart
            width="100%"
            height={tallerChart ? 400 : 280}
            data={income}
          >
            <XAxis dataKey="text" />
            <YAxis tickCount={3} />
            <Tooltip
              labelFormatter={label => label.length === 3 || label.length === 4
                ? label //Month name
                : getLabel(label)
              }
            />
            <Legend align="left" verticalAlign="middle" iconType="circle" />
            <Line strokeWidth={2} type="monotone" dataKey="Commission" stroke={role === 'admin' ? "#FFE1E1" : "#BA4A00"} activeDot={{ r: 8 }} />
            {role === 'admin' && <Line strokeWidth={2} type="monotone" dataKey="Revenue" stroke="#BA4A00" />}
            <Line strokeWidth={2} type="monotone" strokeDasharray="3 3" dataKey="Commission " stroke={role === 'admin' ? "#FFE1E1" : "#BA4A00"} activeDot={{ r: 8 }} />
            {role === 'admin' && <Line strokeWidth={2} type="monotone" strokeDasharray="3 3" dataKey="Revenue " stroke="#BA4A00" />}
          </LineChart>
        </ResponsiveContainer>
      : <div className="no-data">
          <img src={NoData} />
          <p>{t('No data yet')}</p>
        </div>
      }
      {paginated && hasData &&
        <div className="controls">
          <LeftOutlined onClick={() => startAt !== 0 && setStartAt(val => val - 3)} />
          <RightOutlined onClick={() => startAt < income.length && setStartAt(val => val + 3)} />
        </div>
      }
    </div>
  )
}

export default Income;
