import React from 'react'
import numeral from 'numeral';
import { Row, Col } from 'antd'

const formatNumber = (num) => numeral(num).format('0,0.00')

const Overview = ({ t, role, payments, commissions, filters, opportunities }) => {

  const from = filters.dates[0]
  const to = filters.dates[1]

  const getOverallRevenue = () => {
    let amount = 0
    payments.map( payment => {
      payment.events.map( event => {
        if (event.status === 'Cleared' && event.createdAt <= to && event.createdAt >= from) {
          let increment = event.amount || payment.instalmentAmount || payment.value || 0
          if (increment) {
            amount += increment
          }
        }
      })
    })
    return amount
  }

  const getOverallCommission = () => {
    let amount = 0
    commissions.map(com => {
      com.items.map(item => {
        if (item.dueAt <= to && item.dueAt >= from) {
          amount += item.amount
        }
      })
    })
    return amount
  }

  const getAvarageRevenue = () => {
    let totalRevenue = 0;
    payments.map( payment => {
      payment.events.map( event => {
        if (event.createdAt >= from && event.createdAt <= to && event.status === 'Cleared') {
          totalRevenue += event.amount
        }
      })
    })
    let opportunity_amount = payments.length
    return totalRevenue / opportunity_amount
  }

  const getAvarageCommission = () => {
    let commissions_per_opportunity = []
    payments.map( payment => {
      let alreadPaid = 0
      payment.events.map( event => {
        if (event.createdAt >= from && event.createdAt <= to && event.status === 'Cleared') {
          alreadPaid += event.amount
        }
      })
      let commission_amount = alreadPaid * (payment[(role === 'admin' || role === 'superadmin' || !payment.commission_percent_agent) ? 'commission_percent' : `commission_percent_${role}`] || 0) / 100
      commissions_per_opportunity.push(commission_amount)
    })
    return commissions_per_opportunity.reduce((a, b) => a + b, 0) / commissions_per_opportunity.length
  }

  const getPotentialRevenue = () => {
    let amount = 0
    opportunities.map( opp => {
      if (opp.status === 'Open') {
        if (opp.value) {
          amount += opp.value
        }
      }
    })
    return amount
  }

  const getAvaragePotentialRevenue = () => {
    let total_potential_revenue = getPotentialRevenue()
    let open_opportunity_amount = opportunities.filter(opp => opp.status === 'Open').length
    return total_potential_revenue / (open_opportunity_amount || 1)
  }

  return (
    <div className="chart-container">
      <p>{t('Revenue and Commissions')}</p>
      <Row className="numbers">
        <Number text={t('Overall revenue')} amount={getOverallRevenue()} className="primary bigger" />
        <Number text={t('Overall commissions generated')} amount={getOverallCommission()} className="secondary" />
        <Number text={t('Avarage revenue per opportunity')} amount={getAvarageRevenue()} className="primary" />
        <Number text={t('Average commission per opportunity')} amount={getAvarageCommission()} className="secondary" />
        <Number text={t('Potential revenue')} amount={getPotentialRevenue()} starred={true} className="gray bigger" />
        <Number text={t('Avarage potential revenue per opp.')} amount={getAvaragePotentialRevenue()} starred={true} className="gray" />
      </Row>
      <p className="notice title"><sup>*</sup> {t('Potential revenue  based on the current number of open opportunities')}</p>
    </div>
  )
}

const Number = ({ text, amount, className, starred }) => (
  <Col xs={12}>
    <div className={`number ${className}`}>
      {starred
        ? <h4>{text} <sup>*</sup></h4>
        : <h4>{text}</h4>
      }
      <p>RM {formatNumber(amount)}</p>
    </div>
  </Col>
)

export default Overview;