import React, { useState } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import moment from 'moment'
import NoData from '../../../assets/illustrations/nodata.svg'

const InstalmentsChart = ({ t, role, payments, filters, intervals }) => {

  const [startAt, setStartAt] = useState(0)

  const getData = () => {
    let data = []
    intervals.map(int => {
      let instalment = 0
      let full = 0
      payments.map( payment => {
        if (payment.instalmentPeriod === 0) {
          if (payment.updatedAt >= int.from && payment.updatedAt <= int.to) {
            full += 1
          }
        } else {
          payment.events.map( event => {
            if (event.status === 'Cleared' && event.createdAt <= int.to && event.createdAt >= int.from) {
              instalment += 1
            }
          })
        }
      })
      data.push({
        date: int.text,
        Instalment: instalment,
        Full: full
      })
    })
    return data
  }

  const getLabel = (label) => {
    let currentInterval = intervals.find(int => int.text === label)
    if (currentInterval) {
      return `${moment(currentInterval.from).format('DD. MMM')} - ${moment(currentInterval.to).format('DD. MMM')}`
    } else {
      return label
    }
  }

  const data = getData().slice(startAt, startAt + 3)


  const hasData =  getData().filter( item => item.Full || item.Instalment).length !== 0


  return (
    <div className="chart-container">
      <p>{t('Number of Instalments vs. Full')}</p>
      {hasData ?
        <ResponsiveContainer width="80%" height={280}>
          <LineChart
            width="100%"
            height={280}
            data={data}
          >
            <XAxis dataKey="date" />
            <YAxis tickCount={3} />
            <Tooltip
              labelFormatter={label => label.length === 3 || label.length === 4
                ? label //Month name
                : getLabel(label)
              }
            />
            <Legend align="left" verticalAlign="middle" iconType="circle" />
            <Line strokeWidth={2} type="monotone" dataKey="Instalment" stroke="#FFE1E1" activeDot={{ r: 8 }} />
            <Line strokeWidth={2} type="monotone" dataKey="Full" stroke="#BA4A00" />
          </LineChart>
        </ResponsiveContainer>
      : <div className="no-data">
          <img src={NoData} />
          <p>{t('No data yet')}</p>
        </div>
      }
      {hasData &&
        <div className="controls">
          <LeftOutlined onClick={() => startAt !== 0 && setStartAt(val => val - 3)} />
          <RightOutlined onClick={() => startAt < data.length && setStartAt(val => val + 3)} />
        </div>
      }
    </div>
  )
}

export default InstalmentsChart;