import React, { useState } from 'react'
import Link from 'next/link'
import { Row, Col } from 'antd'
import numeral from 'numeral';
import Share from '../../common/ui_components/share'

const formatNumber = (num) => numeral(num).format('0,0.00')

const Promotions = ({t, products, uid, role}) => {

  const [ sharingProductId, setSharingProductId ] = useState(false)


  return (
    <div className="chart-container promotions">
      <p>{t('Promotions')}</p>
      <p className="title">{t('A promotion applies for the following products. It may interest your customers, Create an opportunity for your customer now by sharing. If your opportunity already exist, you can copy paste the name of the product and add it to the opportunity.')}</p>
      {products.map( prod => <Product setSharingProductId={setSharingProductId} product={prod} />)}

      <Share
        type="product"
        t={t}
        id={sharingProductId}
        closeModal={() => setSharingProductId(false)}
        uid={uid}
        role={role}
      />

    </div>
  )
}

const Product = ({product, setSharingProductId}) => (
  <Row className="promotion-product">
    <Col span={5}>
      <div className="discount-rate">-{product.promotion_rate}%</div>
    </Col>
    <Col span={15}>
      <p className="name">{product.label}</p>
      <p className="original-price"><span>RM</span> {getOriginalPrice(product)}</p>
      <p className="price"><span>RM</span> {formatNumber(product.retail_fullpayment)}</p>
    </Col>
    <Col span={4}>
      <div onClick={() => setSharingProductId(product.id)} className="icon"><i className="flaticon-share" /></div>
    </Col>
  </Row>
)

const getOriginalPrice = ({promotion_rate, retail_fullpayment}) => {
  if (promotion_rate) {
    retail_fullpayment = retail_fullpayment / (100 - promotion_rate) * 100
  }
  return formatNumber(retail_fullpayment)
}

export default Promotions;