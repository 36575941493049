const sortColumn = (a, b, column) => {
  const compareA = Number.isInteger(a[column]) ? a[column] :
    typeof a[column] === 'string'
      ? a[column].toLowerCase()
      : a[column]
  const compareB = Number.isInteger(b[column]) ? b[column] :
    typeof b[column] === 'string'
      ? b[column].toLowerCase()
      : b[column]
  return (
    compareA === compareB ? 0 :
      (compareA > compareB ? 1 : -1)
  )
}

export default sortColumn;