import React, { useState } from 'react'
import Income from './income'
import Overview from './overview'
import OpportunitiesChart from './opportunitiesChart'
import InstalmentsChart from './instalmentsChart'
import Leaderboard from './leaderboard'
import NumberOfOpportunities from './numberOfOpportunities'
import AgentsOverview from './agentsOverview'
import { Row, Col, Select } from 'antd'
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { Mobile, Desktop } from '../../utils/responsive'
import Revenue from './revenue'
const { Option } = Select

const AdminDashboard = ({ t, role, opportunities, payments, commissions, filters, intervals, view, users, resellers, income, isMobile }) => {

  const [ selectedUser, setSelectedUser ] = useState(null)
  const selectedUserRole = selectedUser
    ? users.find( user => user.uid === selectedUser).role
    : null

  if (view === 'sales') {
    return (
      <Row>
        <Col xs={24} lg={16} className="padding">
          <Income
            t={t}
            role="admin"
            income={income}
            intervals={intervals}
            paginated={isMobile && isMobile}
            tallerChart={true}
          />
        </Col>
        <Col xs={24} lg={8} className="padding">
          <Overview
            t={t}
            role="admin"
            payments={payments}
            commissions={commissions}
            opportunities={opportunities}
            filters={filters}
          />
        </Col>
        <Col xs={24} md={12} lg={8} className="padding">
          <OpportunitiesChart
            t={t}
            role="admin"
            opportunities={opportunities}
            intervals={intervals}
          />
        </Col>
        <Col xs={24} md={12} lg={8} className="padding">
          <InstalmentsChart
            t={t}
            role="admin"
            payments={payments}
            filters={filters}
            intervals={intervals}
          />
        </Col>
        <Col xs={24} md={24} lg={8} className="padding">
          <NumberOfOpportunities
            t={t}
            role="admin"
            opportunities={opportunities}
          />
        </Col>
      </Row>
    )
  } else {
    return (
      <Row>
        <Col xs={24}><p className="dashboard-section-title">{t('Agents and Resellers Overall performance')}</p></Col>
        <Col xs={24} md={16} className="padding">
          <Leaderboard
            t={t}
            payments={payments}
            commissions={commissions}
            opportunities={opportunities}
            filters={filters}
            users={users}
            isMobile={isMobile}
          />
        </Col>
        <Col xs={24} md={8} className="padding">
          <AgentsOverview
            t={t}
            users={users}
            resellers={resellers}
            filters={filters}
          />
        </Col>
        <Col xs={24} className="user-filter-header">
          <p className="dashboard-section-title">{t('Agent & Reseller Individual Performance')}</p>
          <Select
            className={selectedUser ? 'active' : 'inactive'}
            showSearch={true}
            value={selectedUser}
            onChange={val => setSelectedUser(val)}
            suffixIcon={<SearchOutlined />}
            placeholder={isMobile ? t('Agent/Reseller') : t('Search for an agent or reseller name or ID')}
            size="large"
            allowClear={true}
            clearIcon={<div className="clear"><CloseOutlined /></div>}
          >
            {users.filter(user => user.uid).map( user => <Option value={user.uid}>{user.name}</Option>)}
          </Select>
        </Col>
        <Col xs={24} md={8} className="padding">
          <Revenue
            t={t}
            intervals={intervals}
            selectedUser={selectedUser}
          />
        </Col>
        <Col xs={24} md={8} className="padding agent-opportunities-container">
          <OpportunitiesChart
            t={t}
            role="admin"
            opportunities={selectedUser
              ? opportunities.filter(opp => (selectedUserRole === 'agent' && opp.agentId === selectedUser) || (selectedUserRole === 'reseller' && opp.reseller === selectedUser))
              : []
            }
            intervals={intervals}
          />
        </Col>
        <Col xs={24} md={8} className="padding">
          <AgentsOverview
            t={t}
            individual={true}
            filters={filters}
            users={selectedUser
              ? users.filter(user => user.uid === selectedUser)
              : null
            }
            resellers={selectedUser
              ? resellers.filter(res => res.agent === selectedUser)
              : []
            }
          />
        </Col>
      </Row>
    )
  }
}

export default AdminDashboard;